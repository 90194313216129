<!--
data: 
- title: text
- subTitle: text
- text: text
- image: image (not used in this block)
- buttons: list (title: text, text: text, buttonText: text, url: text)
- bgText: text (not used in this block)
- backgroundImage: image (not used in this block)
- textAlign: select (left: text, center: text, right: text)
- style: select (light: text, dark: text, color: text)
-->

<template>
	<div class="cs-block cs-text-block" :class="styleClass">
		<div class="cs-block-base" :class="rootClasses">
			<div class="container">
				<h2 class="cs-title" v-if="data.title"> {{data.title}} </h2>
				<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
				<span class="cs-text" v-if="data.text" v-html="data.text"></span>
				<div class="btns">
					<Buttons :data="data.buttons" :cs_style="data.style" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "Text",
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		Buttons: () => import("../components/CSUtilsButtons.vue"),
	},
	computed: computed('TextBlock')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-text-block {
	.btns {
        margin-top: $line_margin_sm;
        ::v-deep {
            span.cs-button span, span.cs-button a {
                text-align: center !important;
            }
        }
    }
    .cs-block-base {
        &.cs-align-center * {
            text-align: center;
            h3.cs-sub-title {
                align-items: center;
            }
        }
        &.cs-align-left * {
            text-align: left;
        }
        &.cs-align-right * {
            text-align: right;
            h3.cs-sub-title {
                align-items: flex-end;
            }
        }
		&.cs-align-right ::v-deep {
			.cs-simple-button {
                .button-group {
                    @media (max-width: 500px) {
                        padding-right: 0 !important;
                    }
                }
                .button {
                    @media (max-width: 500px) {
                        justify-content: flex-end !important;
                    }
                }
            }
			.cs-advanced-button .button-group {
                justify-content: flex-end;
			}
		}
		&.cs-align-center ::v-deep {
			.cs-simple-button .button {
				@media (max-width: 500px) {
					justify-content: center !important;
				}
			}
			.cs-advanced-button .button-group {
				@media (max-width: 500px) {
					align-items: center !important;
				}
			}
		}
	}
}
</style>
